<template>
  <div class="class-content">
    <div class="__header">
      <h4>Certificate</h4>
      <div class="dropdown">
        <button
          class="btn cta-small btn btn-warning"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Generate Certificate
        </button>
      </div>
    </div>
    <div class="__body" v-if="isLoading == false">
      <div v-if="certificates.length > 0" class="row">
        <div
          class="col-lg-3 col-md-4"
          v-for="(certificate, certificateIndex) in certificates"
          :key="certificateIndex"
        >
          <div class="pdf-card">
            <div class="pdf_image-wrap">
              <img src="../assets/study-material-img.jpg" />
            </div>
            <div class="pdf_body-wrap">
              <h6>{{ certificate.certificate }}</h6>
              <p class="tag">Certificate</p>
              <a
                href="#"
                class="small-cta"
                @click.prevent="downloadFile(certificate.certificate_code)"
                ><i class="fas fa-download"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <p>Certifiate will be available once you complete the course</p>
      </div>
    </div>
    <webinar-loader v-else></webinar-loader>
    <!-- <div class="paging-wrapper">
      <v-pagination
        v-model="upcomingSchedulePage"
        :pages="upcomingScheduleTotalPages"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="upcomingScheduleChangeHandler"
        v-if="upcomingScheduleTotalClass > 0"
      />
    </div> -->

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Generate Certificate
            </h5>
            <button
              type="button"
              class="btn-close"
              ref="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="saveCertificate">
            <div class="modal-body">
              <div class="form-group col-md-12">
                <label for="batch" class="form-label">Batch</label>
                <select
                  class="form-control"
                  v-model="batch"
                  id="batch"
                  :readonly="academicReadOnly == true"
                >
                  <option value="">Select batch</option>
                  <option
                    v-for="(batch, batchIndex) in batches"
                    :key="batchIndex"
                    :value="batch.id"
                  >
                    {{ batch.title }}
                  </option>
                </select>
                <div
                  class="input-errors"
                  v-for="(error, errorIndex) of v$.batch.$errors"
                  :key="errorIndex"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
              <br />
              <div class="form-group">
                <div class="col-md-12">
                  <label for="userName" class="form-label">User Name</label>
                  <input
                    type="text"
                    v-model="userName"
                    class="form-control"
                    id="userName"
                    :readonly="personalReadOnly == true"
                  />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.userName.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <p>*This name will be added to your certificate</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                :disabled="isGeneratingCertificate == true"
                class="btn cta-primary"
              >
                <span>
                  Submit
                </span>
                <span v-if="isGeneratingCertificate == true">
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CertificateService from "@/services/CertificateService.js";
import errorLog from "@/errorLog";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import CircularLoader from "@/components/loaders/CircularLoader";
import WebinarLoader from "@/components/loaders/WebinarLoader";
//import VPagination from "vue3-pagination";

export default {
  name: "ClassTab",
  components: {
    WebinarLoader,
    CircularLoader
    //VPagination
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      userDetails: [],
      userName: "",
      certificates: [],
      batches: [],
      batch: "",
      isGeneratingCertificate: false,
      isLoading: true,
      status: ""
    };
  },
  created() {
    this.getCertificates();
    this.getUserInfo();
    this.getCheckMemberCompletedEvents();
    this.getBatchesList();
  },
  computed: {
    user() {
      let user = JSON.parse(this.$store.getters["studentAuth/user"]);
      return user;
    }
  },
  validations() {
    return {
      userName: {
        required: helpers.withMessage("Please enter full name", required)
      },
      batch: {
        required: helpers.withMessage("Please select batch", required)
      }
    };
  },
  methods: {
    getCheckMemberCompletedEvents() {
      CertificateService.getCheckMemberCompletedEvents()
        .then(result => {
          this.status = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getCertificates() {
      CertificateService.getCertificates()
        .then(result => {
          this.certificates = result.data;
          console.log(this.certificates);
          this.isLoading = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getUserInfo() {
      CertificateService.getUserInfo()
        .then(result => {
          this.userDetails = result.data;
          console.log(this.userDetails);
          this.userName = this.userDetails.first_name;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    saveCertificate: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isGeneratingCertificate = true;
      const data = {
        user_name: this.userName,
        membership_id: this.batch
      };
      CertificateService.saveCertificate(data)
        .then(result => {
          this.isGeneratingCertificate = true;
          //this.$toast.warning("Please wait... Do not refresh page");
          if (result.data.status == "error") {
            this.$toast.error(result.data.message);
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          } else {
            this.$toast.success(result.data.message);
            this.$refs.closeModal.click();
            this.getCertificates();
          }
        })
        .catch(error => {
          this.isGeneratingCertificate = false;
          errorLog.log(error);
        });
    },
    getBatchesList() {
      CertificateService.getBatchesList()
        .then(result => {
          this.batches = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    downloadFile: function(file) {
      CertificateService.downloadFile(file)
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "certificate";
          link.click();
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/study-material.scss";
</style>
