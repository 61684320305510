import Http from "@/services/Http";
export default {
  saveCertificate(data) {
    return Http().post("/nest/generate/certificate", data);
  },
  getCheckMemberCompletedEvents(data) {
    return Http().post("/nest/check/member/completed-events", data);
  },
  downloadFile(data) {
    return Http().get("/nest/certificate/download?file=" + data, {
      responseType: "arraybuffer"
    });
  },
  getCertificates(data) {
    return Http().post("/nest/certificate/details", data);
  },
  getBatchesList() {
    return Http().post("/nest/list/batches");
  },
  getUserInfo() {
    return Http().post("/nest/user/information");
  }
};
